<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div>
    <cycle-report v-if="isCycle"/>
    <daily-report v-else/>
  </div>
</template>

<script>
export default {
  name: "Report",
  components: {
    CycleReport: () => import("@/components/Farm/Report/CycleReport"),
    DailyReport: () => import("@/components/Farm/Report/DailyReport"),
  },
  computed: {
    isCycle() {
      return this.$route.query.type === 'Cycle'
    },
  },
}
</script>
<style lang="scss">

.progress-wrapper .progress-value {
  color: #000000;

  &.is-more-than-half {
    color: #FFFFFF;
  }
}

.b-table {
  font-size: .86rem;

  th[colspan="0"] {
    display: none;
  }

  .table {
    td, th {
      padding: .25rem .5rem;
    }
  }
}


svg, .avoid-break {
  page-break-inside: avoid;
  break-inside: avoid;
  page-break-after: avoid;
  page-break-before: avoid;
}

@page {
  size: A4 landscape;
}

@media print {
  @page {
    size: A4 landscape;
  }

  html, body {
    width: 297mm;
    height: 210mm;
  }

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  svg, .avoid-break {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

</style>
